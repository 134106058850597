import { lazy } from 'react';

const DashboardPage = lazy(() => import('../pages/dashboard/DashboardPage'));
const LoginPage = lazy(() => import('../pages/auth/login/LoginPage'));
const RegisterPage = lazy(() => import('../pages/auth/register/RegisterPage'));
const ForgotPasswordPage = lazy(() => import('../pages/auth/forgot-password/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('../pages/auth/reset-password/ResetPasswordPage'));
const WishListPage = lazy(() => import('../pages/wishlist/WishListPage'));
const ShopPage = lazy(() => import('../pages/shop/ShopPage'));
const SearchPage = lazy(() => import('../pages/search/SearchPage'));
const ProductPage = lazy(() => import('../pages/product/ProductPage'));
const CartPage = lazy(() => import('../pages/cart/CartPage'));
const CheckoutPage = lazy(() => import('../pages/checkout/CheckoutPage'));
const PayDetail = lazy(() => import('../pages/pay-detail/PayDetail'));
const InvoicePage = lazy(() => import('../pages/invoice/InvoicePage'));


export const RouterData = [
  { path: `/`, element: <DashboardPage /> },
  { path: `/login`, element: <LoginPage /> },
  { path: `/register`, element: <RegisterPage /> },
  { path: `/forgot-password`, element: <ForgotPasswordPage /> },
  { path: `/reset-password`, element: <ResetPasswordPage /> },
  { path: `/wishlist`, element: <WishListPage /> },
  { path: `/shop`, element: <ShopPage /> },
  { path: `/shop/:id`, element: <ShopPage /> },
  { path: `/search`, element: <SearchPage /> },
  { path: `/product/:id`, element: <ProductPage /> },
  { path: `/cart`, element: <CartPage /> },
  { path: `/checkout`, element: <CheckoutPage /> },
  { path: `/pay-detail`, element: <PayDetail /> },
  { path: `/invoice/:id`, element: <InvoicePage /> },
];